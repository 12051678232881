import {
  createProvider,
  PropsWithChildren,
  ProviderComposer,
} from '@ac/react-infrastructure';

import { AuthenticationContextProvider } from '@translations/features/application/features/login/store/Provider';

const providers = [
  createProvider({ component: AuthenticationContextProvider }),
];

export const AppProviders = (props: PropsWithChildren): JSX.Element => (
  <ProviderComposer providers={providers}>{props.children}</ProviderComposer>
);
