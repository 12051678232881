/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { FC, PropsWithChildren, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { LoaderSize } from '@ac/web-components';

import { APPLICATION_ROUTES } from '@translations/features/application/config/routes.config';
import { AuthenticatedUserType } from '@translations/features/application/features/login/store/state';
import { Menu } from '@translations/features/application/features/menu/Menu';
import {
  ApplicationRouteProps,
  useApplicationRoute,
} from '@translations/features/application/hooks/useApplicationRoute';

interface AppRouteContentProps
  extends PropsWithChildren,
    ApplicationRouteProps {
  breadcrumbs?: string;
}

export const AppRouteContent: FC<AppRouteContentProps> = ({
  title = '',
  breadcrumbs = '',
  requiredUserType = AuthenticatedUserType.Anonymous,
  children,
}): JSX.Element => {
  const { ready, authorized } = useApplicationRoute({
    title,
    requiredUserType,
  });

  if (!ready && !authorized) {
    return <ac-loader-covering loaderSize={LoaderSize.sm} />;
  }

  return (
    <ac-layout
      gridTemplate="'menu' 'body'"
      gridAutoRows="var(--menu-height) auto"
    >
      <ac-layout-item area="menu">
        <Menu title={title} breadcrumbs={breadcrumbs} />
      </ac-layout-item>
      <ac-layout-item area="body" class="overflow-hidden">
        {((): ReactNode => {
          if (!ready) {
            return <ac-loader-covering loaderSize={LoaderSize.sm} />;
          }

          if (ready && !authorized) {
            return <Navigate to={APPLICATION_ROUTES.ACCESS_DENIED} replace />;
          }

          return children;
        })()}
      </ac-layout-item>
    </ac-layout>
  );
};
