import { useEffect } from 'react';

import { useFetchCompletenessData } from '@translations/features/dashboard/commands/fetchCompletenessData';

export const useSetUp = (): void => {
  const fetchCompletenessData = useFetchCompletenessData();

  useEffect(() => {
    fetchCompletenessData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
