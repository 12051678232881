/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import {
  FlexDirection,
  InfotipSlot,
  OpeningBehavior,
  Placement,
  TargetValueObject,
} from '@ac/web-components';

import { useAuthenticationContext } from '@translations/features/application/features/login/store/context';
import { UserDropdownBody } from '@translations/features/application/features/menu/UserDropdown/UserDropdownBody';
import { UserDropdownHeader } from '@translations/features/application/features/menu/UserDropdown/UserDropdownHeader';
import { UserDropdownTrigger } from '@translations/features/application/features/menu/UserDropdown/UserDropdownTrigger';

import './UserDropdown.scss';

const componentId = 'menu-logged-user';

export const UserDropdown = (): JSX.Element | null => {
  const {
    state: { currentUser },
  } = useAuthenticationContext();

  const { firstName, lastName } = currentUser?.personalData || {};

  if (!currentUser) {
    return null;
  }

  return (
    <ac-infotip
      class="user-dropdown ac-margin-inline-end-md"
      for={`#${componentId}`}
      openingBehavior={OpeningBehavior.onMouseOver}
      placement={Placement.bottomEnd}
      positionWrapperClass="menu-subitems-wrapper"
      targetValue={TargetValueObject.mainMenu}
    >
      <div id={componentId} slot={InfotipSlot.component}>
        <UserDropdownTrigger firstName={firstName} lastName={lastName} />
      </div>
      <div className="user-dropdown">
        <UserDropdownHeader
          firstName={firstName}
          lastName={lastName}
          email={currentUser?.email}
        />
        <ac-flex class="user-dropdown-body" direction={FlexDirection.column}>
          <UserDropdownBody />
        </ac-flex>
      </div>
    </ac-infotip>
  );
};
