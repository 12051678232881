import { Dashboard } from '@translations/features/dashboard/Dashboard';
import { DashboardProviders } from '@translations/features/dashboard/Providers';

export const DashboardContainer = (): JSX.Element => {
  return (
    <DashboardProviders>
      <Dashboard />
    </DashboardProviders>
  );
};
