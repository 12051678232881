/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { Link } from 'react-router-dom';

import { Color, FlexDirection, IconName, TextWeight } from '@ac/web-components';

interface MenuDropdownItemProps {
  title: string;
  options: MenuDropdownItemOption[];
}

interface MenuDropdownItemOption {
  name: string;
  url: string;
  onClickCallback: () => void;
}

export const MenuDropdownItem = ({
  title,
  options,
}: MenuDropdownItemProps): JSX.Element => {
  return (
    <ac-flex class="ac-padding-lg">
      <ac-icon
        color={Color.black}
        icon={IconName.arrowRight}
        class="ac-spacing-top-xxs"
      />
      <ac-flex class="ac-spacing-left-sm" direction={FlexDirection.column}>
        <ac-text class="ac-spacing-bottom-sm" weight={TextWeight.semibold}>
          {title}
        </ac-text>
        {options.map((option) => {
          const optionName = option.name;

          return (
            <Link
              key={optionName}
              className="menu-dropdown-link"
              to={option.url}
              onClick={option.onClickCallback}
            >
              <ac-text key={optionName} class="menu-dropdown-item">
                {optionName}
              </ac-text>
            </Link>
          );
        })}
      </ac-flex>
    </ac-flex>
  );
};
