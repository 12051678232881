import Highcharts from 'highcharts';
import highchartsDrilldown from 'highcharts/modules/drilldown';

import { useSetUp } from '@translations/features/dashboard/commands/setUp';
import { CompletenessChart } from '@translations/features/dashboard/features/completenessChart/CompletenessChart';

highchartsDrilldown(Highcharts);

export const Dashboard = (): JSX.Element => {
  useSetUp();

  return (
    <div className="overflow-y-auto">
      <CompletenessChart />
    </div>
  );
};
