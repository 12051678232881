import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { updateDocumentTitle } from '@ac/library-utils/dist/utils';

import { useAuthorize } from '@translations/features/application/features/login/commands/authorize';
import { useGetAuthenticatedUserType } from '@translations/features/application/features/login/commands/getAutenticatedUserType';
import { useAuthenticationContext } from '@translations/features/application/features/login/store/context';
import { AuthenticatedUserType } from '@translations/features/application/features/login/store/state';
import { getCurrentUrl } from '@translations/features/application/features/login/utils/getCurrentUrl';
import { LoadingStatus } from '@translations/types/shared';

export interface ApplicationRouteProps {
  title?: string;
  requiredUserType?: AuthenticatedUserType;
}

interface Result {
  ready: boolean;
  authorized: boolean;
}

export const useApplicationRoute = ({
  title,
  requiredUserType = AuthenticatedUserType.Anonymous,
}: ApplicationRouteProps): Result => {
  const {
    state: { loadingStatus },
  } = useAuthenticationContext();
  const authorize = useAuthorize();
  const authenticatedUserType = useGetAuthenticatedUserType();
  const { t } = useTranslation();
  const appTitle: string = t('APP.TITLE');

  const checkAuthentication = (): void => {
    if (loadingStatus === LoadingStatus.NotSet) {
      authorize({
        returnUrl: getCurrentUrl(),
      });

      return;
    }
  };

  useEffect(() => {
    checkAuthentication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (title) {
      updateDocumentTitle(`${appTitle} - ${title}`);
    } else {
      updateDocumentTitle(`${appTitle}`);
    }
  }, [appTitle, title]);

  return {
    ready: loadingStatus === LoadingStatus.Success,
    authorized: authenticatedUserType >= requiredUserType,
  };
};
