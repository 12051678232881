import {
  createProvider,
  PropsWithChildren,
  ProviderComposer,
} from '@ac/react-infrastructure';

import { DashboardContextProvider } from '@translations/features/dashboard/store/Provider';

export const DashboardProviders = (props: PropsWithChildren): JSX.Element => {
  const providers = [createProvider({ component: DashboardContextProvider })];

  return (
    <ProviderComposer providers={providers}>{props.children}</ProviderComposer>
  );
};
