import { PropsWithChildren } from '@ac/react-infrastructure';

import { AuthenticationContext } from '@translations/features/application/features/login/store/context';
import { useAuthenticationStore } from '@translations/features/application/features/login/store/store';

export const AuthenticationContextProvider = (
  props: PropsWithChildren
): JSX.Element => {
  const store = useAuthenticationStore();

  return (
    <AuthenticationContext.Provider value={store}>
      {props.children}
    </AuthenticationContext.Provider>
  );
};
