import { DashboardCompletenessCatalog } from '@translations/features/dashboard/types/types';
import { LoadingStatus } from '@translations/types/shared';

export interface DashboardState {
  completenessCatalog?: DashboardCompletenessCatalog;
  loadingStatus: LoadingStatus;
}

export const getDefaultDashboardStateState = (): DashboardState => ({
  completenessCatalog: undefined,
  loadingStatus: LoadingStatus.NotSet,
});
