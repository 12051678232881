import { BaseApi } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations/config';
import { defineCustomElements } from '@ac/web-components/dist/loader';

import { initI18nTranslations } from '@translations/initI18nTranslations';

export const initializeApp = (): void => {
  defineCustomElements(window);
  BaseApi.host = acConfig.apiUrl;
  initI18nTranslations();
};
