/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */

import { AppRouting } from '@translations/features/application/AppRouting';
import { AppProviders } from '@translations/features/application/Providers';

export const AppContainer = (): JSX.Element => {
  return (
    <AppProviders>
      <AppRouting />
    </AppProviders>
  );
};
