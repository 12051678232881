import { RefObject, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { languageCodeToLanguageInfo } from '@translations/constants/languages';
import { useDashboardContext } from '@translations/features/dashboard/store/context';
import { DashboardCompletenessResult } from '@translations/features/dashboard/types/types';

const barWidth = 32;
const barsGap = 32;

const getLanguageLabel = (
  languageCode: string,
  textOnly: boolean = false,
  percentage: string = ''
): string => {
  const languageInfo = languageCodeToLanguageInfo[languageCode];

  if (textOnly) {
    return languageInfo?.name ?? languageCode;
  }

  return `
    <div class="completeness-chart-label completeness-chart-label-language">  
      <div class="completeness-chart-label-text">${
        languageInfo?.name ?? languageCode
      }</div>
      ${
        languageInfo?.flag
          ? `<img class="completeness-chart-label-flag" src="/assets/flags/${languageInfo.flag}"/>`
          : ''
      }
      <div class="completeness-chart-label-percentage">${percentage}</div>
    </div>
  `;
};

const isModuleName = (text: string): boolean => {
  return `${text}`.startsWith('frontend-');
};

const getModuleNameLabel = (labelValue: string, percentage: string): string => {
  const text = labelValue
    .replace('frontend-', '')
    .split('-')
    .map((part) => `${part.substring(0, 1).toUpperCase()}${part.substring(1)}`)
    .join(' ');

  return `
    <div class="completeness-chart-label">  
      <div class="completeness-chart-label-text">${text}</div>
      <div class="completeness-chart-label-percentage">${percentage}</div>
    </div>
  `;
};

const getXAxisLabel = (labelValue: string, percentage: string): string => {
  if (isModuleName(labelValue)) {
    return getModuleNameLabel(labelValue, percentage);
  }

  return getLanguageLabel(labelValue, false, percentage);
};

const formatPercentage = (percentage: number): string =>
  `${(Math.round(Math.round(percentage * 100) / 10) / 10).toFixed(1)}%`;

const getClassNameByPercentage = (percentage: number): string => {
  const baseClassName = 'completeness-bar-';
  if (percentage <= 25) {
    return `${baseClassName}0`;
  }
  if (percentage > 25 && percentage <= 50) {
    return `${baseClassName}1`;
  }
  if (percentage > 50 && percentage <= 75) {
    return `${baseClassName}2`;
  }

  // > 75
  return `${baseClassName}3`;
};

const gradientDef = {
  tagName: 'linearGradient',
  id: '',
  x1: 0,
  y1: 0,
  x2: 0,
  y2: 1,
  children: [
    {
      tagName: 'stop',
      offset: 0,
    },
    {
      tagName: 'stop',
      offset: 1,
    },
  ],
};

const createLinearGradient = (id: string): typeof gradientDef => {
  return { ...gradientDef, id };
};

export const useCompletenessChartOptions = (
  ref: RefObject<HighchartsReact.RefObject | null>
): Highcharts.Options => {
  const { t } = useTranslation();
  const {
    state: { completenessCatalog },
  } = useDashboardContext();

  return useMemo((): Highcharts.Options => {
    const data = completenessCatalog ?? {};
    const modules: string[] = Object.keys(data);

    const languages: string[] = (data?.overall ?? []).map(
      (item: DashboardCompletenessResult) => item.language
    );

    const series = [
      {
        name: 'Languages',
        colorByPoint: true,
        clip: false,
        data: languages.map((language) => {
          const completeness = (data?.overall ?? []).find(
            (item) => item.language === language
          );
          const percentage = completeness?.percentage ?? 0;

          return {
            name: language,
            drilldown: language,
            y: completeness?.percentage ?? 0,
            pointWidth: barWidth,
            className: getClassNameByPercentage(percentage),
          };
        }),
      },
    ];

    const drilldownSeries = languages.map((language) => {
      const seriesData: Array<{
        name: string;
        y: number;
        pointWidth: number;
        className: string;
      }> = [];
      modules
        .filter((moduleName) => moduleName !== 'overall')
        .map((moduleName: string) => {
          const completeness = (data?.[moduleName] ?? []).find(
            (item) => item.language === language
          );
          const percentage = completeness?.percentage ?? 0;
          seriesData.push({
            name: moduleName,
            y: percentage,
            pointWidth: barWidth,
            className: getClassNameByPercentage(percentage),
          });
        });

      return {
        name: language,
        id: language,
        data: seriesData,
      };
    });

    const removeSizeAndReflow = (): void => {
      ref.current?.chart.setSize(null, null, false);
      const options = ref.current?.chart.options;
      const newOptions = {
        ...options,
        chart: { ...options?.chart, reflow: true },
      };
      ref.current?.chart.update(newOptions, true, true, false);
    };

    const setSizeAndCleanup = (
      width: number | null,
      height: number | null,
      nextTick: boolean = false
    ): void => {
      const job = (): void => {
        const w = width === null ? ref.current?.chart.chartWidth : width;
        const h = height === null ? ref.current?.chart.chartHeight : height;
        ref.current?.chart.setSize(w, h, false);
        removeSizeAndReflow();
      };
      if (nextTick) {
        setTimeout(job, 0);
      } else {
        job();
      }
    };

    return {
      title: undefined,
      chart: {
        className: 'completeness-chart',
        styledMode: true,
        // calculate proper height base on number of modules.
        // chart height is not adjusting properly when switching to modules view
        height: (series?.[0]?.data?.length ?? 0) * (barsGap + barWidth),
        reflow: true,
        type: 'bar',
        events: {
          load: () => {
            setTimeout((): void => {
              const width = ref.current?.chart.chartWidth ?? 0;
              setSizeAndCleanup(width - 1, null);
              // zero didn't work unfortunately
            }, 100);
          },
          drilldown: () => {
            setSizeAndCleanup(
              (ref.current?.chart.chartWidth ?? 0) - 1,
              (drilldownSeries?.[0]?.data?.length ?? 0) * (barWidth + barsGap),
              true
            );
          },
          drillup: () => {
            setSizeAndCleanup(
              (ref.current?.chart.chartWidth ?? 0) - 1,
              (series?.[0]?.data?.length ?? 0) * (barWidth + barsGap),
              true
            );
          },
        },
      },
      defs: {
        gradient0: createLinearGradient('gradient-0'),
        gradient1: createLinearGradient('gradient-1'),
        gradient2: createLinearGradient('gradient-2'),
        gradient3: createLinearGradient('gradient-3'),
      },
      accessibility: {
        announceNewData: {
          enabled: true,
        },
      },
      xAxis: {
        type: 'category',
        labels: {
          y: -6,
          x: -48,
          useHTML: true,
          formatter(this: Highcharts.AxisLabelsFormatterContextObject): string {
            return getXAxisLabel(
              `${this.value}`,
              formatPercentage(this.axis.series[0]?.data?.[this.pos]?.y ?? 0)
            );
          },
        },
      },
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: t('DASHBOARDS.COMPLETENESS.COMPLETENESS_CHART.TOTAL_PERCENT'),
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          borderRadius: {
            radius: 4,
            where: 'all',
          },
        },
      },

      tooltip: false,

      series,
      drilldown: {
        breadcrumbs: {
          formatter: (level: Highcharts.BreadcrumbOptions): string => {
            return getLanguageLabel(level.levelOptions.name ?? '', true);
          },
          useHTML: true,
          position: {
            align: 'right',
          },
        },
        activeAxisLabelStyle: {
          textDecoration: 'none',
        },
        activeDataLabelStyle: {
          textDecoration: 'none',
        },
        series: drilldownSeries,
      },
      // drilldown chart types are buggy and incomplete
    } as unknown as Highcharts.Options;
  }, [completenessCatalog, ref, t]);
};
