import { LoginService } from '@ac/library-utils/dist/services';

import { AuthenticatedUserType } from '@translations/features/application/features/login/store/state';

export const checkAuthenticatedUserType = (): AuthenticatedUserType => {
  if (LoginService.isLoggedIn()) {
    if (LoginService.isSuperUser()) {
      return AuthenticatedUserType.SuperUser;
    }

    return AuthenticatedUserType.Authenticated;
  }

  return AuthenticatedUserType.Anonymous;
};
