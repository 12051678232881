import { createContext } from 'react';

import { useDefinedContext } from '@ac/react-infrastructure';

import { DashboardStore } from '@translations/features/dashboard/store/store';

export const DashboardContext = createContext<DashboardStore | undefined>(
  undefined
);

export const useDashboardContext = (): DashboardStore =>
  useDefinedContext(DashboardContext);
