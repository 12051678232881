/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { useTranslation } from 'react-i18next';

import { LoginService } from '@ac/library-utils/dist/services';

import { APPLICATION_ROUTES } from '@translations/features/application/config/routes.config';

export const UserDropdownBody = (): JSX.Element => {
  const { t } = useTranslation();
  const logout = async (): Promise<void> => {
    LoginService.setPostLogoutRedirectUri(APPLICATION_ROUTES.LOGIN);
    await LoginService.logout();
  };

  return (
    <a className="user-dropdown-link" onClick={logout} href="">{`${t(
      'APP.MENU.USER.LOGOUT'
    )}`}</a>
  );
};
