import { LogDetails } from '@ac/library-utils/dist/declarations';
import { LoginService } from '@ac/library-utils/dist/services';
import { setUpLibraryApiForModule } from '@ac/react-infrastructure';

import { APPLICATION_CONSTANTS } from '@translations/constants/app.constants';
import { useGetCurrentUser } from '@translations/features/application/features/login/commands/getCurrentUser';
import { checkAuthenticatedUserType } from '@translations/features/application/features/login/store/checkAuthenticatedUserType';
import { useAuthenticationContext } from '@translations/features/application/features/login/store/context';
import { getLoginUrl } from '@translations/features/application/features/login/utils/getLoginUrl';
import { LoadingStatus } from '@translations/types/shared';

interface Parameters {
  onSuccess?: () => void;
  returnUrl?: string;
}

type Command = (parameters: Parameters) => void;

export const useAuthorize = (): Command => {
  const authenticationContext = useAuthenticationContext();
  const getCurrentUser = useGetCurrentUser();

  return ({ onSuccess, returnUrl }: Parameters = {}): void => {
    const loginUrl = getLoginUrl();

    const proceedSuccessfulAuthentication = async (): Promise<void> => {
      setUpLibraryApiForModule({ skipPropertyHeader: true });
      await getCurrentUser();
      authenticationContext.setAuthenticatedUserType(
        checkAuthenticatedUserType()
      );
      onSuccess?.();
      authenticationContext.setLoadingStatus(LoadingStatus.Success);
    };

    LoginService.overrideIdentityClientId(APPLICATION_CONSTANTS.CLIENT_ID);
    LoginService.init((message: string | LogDetails, params) => {
      // eslint-disable-next-line no-console
      console.log(message, params);
    });
    LoginService.setRedirectUri(loginUrl);
    LoginService.setOnSuccessHandler(proceedSuccessfulAuthentication);

    authenticationContext.setLoadingStatus(LoadingStatus.Loading);
    LoginService.handleModuleCallBack({
      moduleLoginUrl: loginUrl,
      returnUrl: returnUrl || loginUrl,
    });
  };
};
