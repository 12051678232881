import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import backend from 'i18next-http-backend';
import moment from 'moment';

import { setUpTranslationsForStandaloneApplication } from '@ac/library-utils/dist/translations/react/standalone';

import { APPLICATION_CONSTANTS } from '@translations/constants/app.constants';

export { ApplicationLanguages } from '@ac/library-utils/dist/translations/applicationLanguages';

export const initI18nTranslations = (): void => {
  setUpTranslationsForStandaloneApplication({
    dateUtilsLibraryInstance: moment,
    i18n,
    config: {
      plugins: { backend, initReactI18next },
      moduleName: 'translations',
      repositoryName: 'frontend-translations',
      useRemoteTranslations: false,
      localTranslationsDirectory: 'i18n/frontend-translations',
      initOptions: {
        lng: APPLICATION_CONSTANTS.DEFAULT_LANGUAGE_CODE,
        interpolation: { escapeValue: false },
      },
    },
  });
};

export const changeLanguage = async (language?: string): Promise<void> => {
  if (!language) return;

  await i18n.changeLanguage(language);
  document.dir = i18n.dir();
};
