export interface LanguageInfo {
  name: string;
  flag?: string;
}

export const languageCodeToLanguageInfo: Record<string, LanguageInfo> = {
  en: { name: 'English', flag: 'flag-united-kingdom.svg' },
  ar: { name: 'Arabic', flag: 'flag-arabic.svg' },
  de: { name: 'German', flag: 'flag-germany.svg' },
  nl: { name: 'Dutch', flag: 'flag-dutch.svg' },
  es: { name: 'Spanish', flag: 'flag-spain.svg' },
  fr: { name: 'French', flag: 'flag-france.svg' },
  it: { name: 'Italian', flag: 'flag-italy.svg' },
  ja: { name: 'Japanese', flag: 'flag-japan.svg' },
  ko: { name: 'Korean', flag: 'flag-korea.svg' },
  pl: { name: 'Polish', flag: 'flag-poland.svg' },
  th: { name: 'Thai', flag: 'flag-thailand.svg' },
  tr: { name: 'Turkish', flag: 'flag-turkiye.svg' },
  'zh-Hans': { name: 'Chinese (Simplified)', flag: 'flag-china.svg' },
  'zh-Hant': { name: 'Chinese (Traditional)', flag: 'flag-china.svg' },
};
