/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { useTranslation } from 'react-i18next';

import { APPLICATION_ROUTES } from '@translations/features/application/config/routes.config';
import { MenuDropdownItem } from '@translations/features/application/features/menu/MenuDropdownItem/MenuDropdownItem';

import './MenuDropdown.scss';

interface MenuDropdownProps {
  onClickOutside: () => void;
}

export const MenuDropdown = ({
  onClickOutside,
}: MenuDropdownProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="menu-dropdown-container">
      <ac-click-outside onClickOutsideCallback={onClickOutside}>
        <MenuDropdownItem
          title={t('APP.MENU.ITEMS.TRANSLATIONS.TITLE')}
          options={[
            {
              name: t('APP.MENU.ITEMS.TRANSLATIONS.OPTIONS.COMPLETENESS'),
              url: APPLICATION_ROUTES.DASHBOARD,
              onClickCallback: onClickOutside,
            },
          ]}
        />
      </ac-click-outside>
    </div>
  );
};
