import { UserDetails } from '@ac/library-api/dist/types';

import { checkAuthenticatedUserType } from '@translations/features/application/features/login/store/checkAuthenticatedUserType';
import { LoadingStatus } from '@translations/types/shared';

export enum AuthenticatedUserType {
  Anonymous = 0,
  Authenticated = 100,
  SuperUser = 200,
}

export interface AuthenticationState {
  authenticatedUserType: AuthenticatedUserType;
  currentUser?: UserDetails;
  loadingStatus: LoadingStatus;
}

export const getDefaultAuthenticationState = (): AuthenticationState => ({
  authenticatedUserType: checkAuthenticatedUserType(),
  loadingStatus: LoadingStatus.NotSet,
});
