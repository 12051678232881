import { useDashboardContext } from '@translations/features/dashboard/store/context';
import { DashboardCompletenessCatalog } from '@translations/features/dashboard/types/types';
import { LoadingStatus } from '@translations/types/shared';

type CommandResult = () => Promise<void>;

export const useFetchCompletenessData = (): CommandResult => {
  const store = useDashboardContext();

  return async (): Promise<void> => {
    store.setLoadingStatus(LoadingStatus.Loading);
    try {
      const response = await fetch(
        `${window.location.origin}/i18n-data/completeness.json`
      );
      const data = (await response.json()) as DashboardCompletenessCatalog;
      store.setLoadingStatus(LoadingStatus.Success);
      store.setCompletenessCatalog(data);
    } catch (error) {
      store.setLoadingStatus(LoadingStatus.Error);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
};
