import { useState } from 'react';

import {
  DashboardState,
  getDefaultDashboardStateState,
} from '@translations/features/dashboard/store/state';
import { DashboardCompletenessCatalog } from '@translations/features/dashboard/types/types';
import { LoadingStatus } from '@translations/types/shared';

export interface DashboardStore {
  state: DashboardState;
  setCompletenessCatalog: (
    completenessCatalog: DashboardCompletenessCatalog
  ) => void;
  setLoadingStatus: (status: LoadingStatus) => void;
}

export const useDashboardStore = (): DashboardStore => {
  const [state, setState] = useState<DashboardState>(
    getDefaultDashboardStateState()
  );

  const setCompletenessCatalog = (
    completenessCatalog: DashboardCompletenessCatalog
  ): void => setPartialState({ completenessCatalog });

  const setLoadingStatus = (status: LoadingStatus): void =>
    setPartialState({ loadingStatus: status });

  const setPartialState = (newState: Partial<DashboardState>): void =>
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));

  return {
    state,
    setLoadingStatus,
    setCompletenessCatalog,
  };
};
