import { useRef } from 'react';
import Highcharts from 'highcharts';
import highchartsDrilldown from 'highcharts/modules/drilldown';
import HighchartsReact from 'highcharts-react-official';

import { useCompletenessChartOptions } from '@translations/features/dashboard/features/completenessChart/commands/completenessChartOptions';
import { useDashboardContext } from '@translations/features/dashboard/store/context';
import { testSelectors } from '@translations/features/dashboard/testSelectors';
import { LoadingStatus } from '@translations/types/shared';

import 'highcharts/css/highcharts.css';
import './CompletenessChart.scss';

highchartsDrilldown(Highcharts);

export const CompletenessChart = (): JSX.Element => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const chartOptions = useCompletenessChartOptions(chartComponentRef);
  const {
    state: { loadingStatus },
  } = useDashboardContext();

  const isLoading = loadingStatus !== LoadingStatus.Success;

  if (isLoading) {
    return <ac-loader-covering />;
  }

  return (
    <div
      className="highcharts-light"
      data-test-selector={testSelectors.completenessChart}
    >
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        ref={chartComponentRef}
      />
    </div>
  );
};
