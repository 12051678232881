import { useAuthenticationContext } from '@translations/features/application/features/login/store/context';
import { AuthenticatedUserType } from '@translations/features/application/features/login/store/state';

export const useGetAuthenticatedUserType = (): AuthenticatedUserType => {
  const {
    state: { authenticatedUserType },
  } = useAuthenticationContext();

  return authenticatedUserType;
};
