/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */

import { useTranslation } from 'react-i18next';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { AccessDeniedContainer } from '@ac/react-infrastructure';
import { Errors } from '@ac/web-components';

import { AppRouteContent } from '@translations/features/application/AppRouteContent';
import { APPLICATION_ROUTES } from '@translations/features/application/config/routes.config';
import { LoginContainer } from '@translations/features/application/features/login/LoginContainer';
import { AuthenticatedUserType } from '@translations/features/application/features/login/store/state';
import { DashboardContainer } from '@translations/features/dashboard/DashboardContainer';

const buildBreadcrumbs = (...titles: string[]): string => titles.join(' > ');

export const AppRouting = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={APPLICATION_ROUTES.DASHBOARD}
          element={
            <AppRouteContent
              title={t('APP.MENU.ITEMS.TRANSLATIONS.OPTIONS.COMPLETENESS')}
              breadcrumbs={buildBreadcrumbs(
                t('APP.MENU.ITEMS.TRANSLATIONS.TITLE'),
                t('APP.MENU.ITEMS.TRANSLATIONS.OPTIONS.COMPLETENESS')
              )}
              requiredUserType={AuthenticatedUserType.SuperUser}
            >
              <DashboardContainer />
            </AppRouteContent>
          }
        />
        <Route path={APPLICATION_ROUTES.LOGIN} element={<LoginContainer />} />
        <Route
          path="/"
          element={<Navigate to={APPLICATION_ROUTES.DASHBOARD} replace />}
        />
        <Route
          path={APPLICATION_ROUTES.ACCESS_DENIED}
          element={
            <AppRouteContent title={t('APP.ACCESS_DENIED')}>
              <AccessDeniedContainer />
            </AppRouteContent>
          }
        />
        <Route
          path={APPLICATION_ROUTES.NOT_FOUND}
          element={
            <AppRouteContent title={t('APP.NOT_FOUND')}>
              <ac-error-page errorType={Errors.error404} />
            </AppRouteContent>
          }
        />
        <Route
          path="*"
          element={<Navigate to={APPLICATION_ROUTES.NOT_FOUND} replace />}
        />
      </Routes>
    </BrowserRouter>
  );
};
