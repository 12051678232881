import { createContext } from 'react';

import { useDefinedContext } from '@ac/react-infrastructure';

import { AuthenticationStore } from '@translations/features/application/features/login/store/store';

export const AuthenticationContext = createContext<
  AuthenticationStore | undefined
>(undefined);

export const useAuthenticationContext = (): AuthenticationStore =>
  useDefinedContext(AuthenticationContext);
