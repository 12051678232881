import { PropsWithChildren } from '@ac/react-infrastructure';

import { DashboardContext } from '@translations/features/dashboard/store/context';
import { useDashboardStore } from '@translations/features/dashboard/store/store';

export const DashboardContextProvider = (
  props: PropsWithChildren
): JSX.Element => {
  const store = useDashboardStore();

  return (
    <DashboardContext.Provider value={store}>
      {props.children}
    </DashboardContext.Provider>
  );
};
