import { UsersApi } from '@ac/library-api/dist/api/v0/identity';
import { UserDetails } from '@ac/library-api/dist/types';

import { useAuthenticationContext } from '@translations/features/application/features/login/store/context';

type Result = () => Promise<void>;

export const useGetCurrentUser = (): Result => {
  const { setCurrentUser } = useAuthenticationContext();

  return async (): Promise<void> => {
    const user = (await UsersApi.getMe()) as UserDetails;
    setCurrentUser(user);
  };
};
