/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { useState } from 'react';

import {
  AlignItems,
  Color,
  FlexDirection,
  IconName,
  JustifyContent,
  TextColor,
  TextSize,
  TextWeight,
} from '@ac/web-components';

import { MenuDropdown } from '@translations/features/application/features/menu/MenuDropdown/MenuDropdown';
import { UserDropdown } from '@translations/features/application/features/menu/UserDropdown/UserDropdown';

import './Menu.scss';

interface MenuProps {
  title: string;
  breadcrumbs: string;
}

export const Menu = ({ breadcrumbs, title }: MenuProps): JSX.Element => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleMenuClick = (event: MouseEvent): void => {
    event.stopPropagation();
    setIsDropdownVisible(!isDropdownVisible);
  };

  return (
    <>
      <ac-flex grow class="menu-items-container" alignItems={AlignItems.center}>
        <ac-box>
          <ac-flex alignItems={AlignItems.center}>
            <ac-icon
              class="ac-spacing-horizontal-lg menu-items-container-icon"
              icon={isDropdownVisible ? IconName.cancel : IconName.menu}
              color={Color.white}
              onClick={handleMenuClick}
            />
            <ac-brand-logo />
            <ac-flex
              class="ac-spacing-left-lg"
              direction={FlexDirection.column}
            >
              <ac-text color={TextColor.white} size={TextSize.sm}>
                {breadcrumbs}
              </ac-text>
              <ac-text
                color={TextColor.white}
                weight={TextWeight.semibold}
                size={TextSize.lg}
              >
                {title}
              </ac-text>
            </ac-flex>
          </ac-flex>
        </ac-box>
        <ac-box grow>
          <ac-flex justifyContent={JustifyContent.flexEnd}>
            <UserDropdown />
          </ac-flex>
        </ac-box>
      </ac-flex>
      {isDropdownVisible && (
        <MenuDropdown
          onClickOutside={(): void => {
            setIsDropdownVisible(false);
          }}
        />
      )}
    </>
  );
};
