/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import * as ReactDOM from 'react-dom/client';

import { APPLICATION_CONSTANTS } from '@translations/constants/app.constants';
import { AppContainer } from '@translations/features/application/AppContainer';
import { initializeApp } from '@translations/initializeApp';

import '@ac/web-components/dist/web-components/web-components.css';
import './index.scss';

initializeApp();

const appContainerRoot = document.getElementById(
  APPLICATION_CONSTANTS.APP_ROOT_CONTAINER_ID
);

if (appContainerRoot) {
  ReactDOM.createRoot(appContainerRoot).render(<AppContainer />);
}
