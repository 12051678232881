/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { isDefined } from '@ac/library-utils/dist/utils';
import {
  AlignItems,
  FlexDirection,
  IconName,
  TextColor,
  TextWeight,
} from '@ac/web-components';

interface Props {
  firstName?: string;
  lastName?: string;
  email?: string;
}

export const UserDropdownHeader = ({
  firstName,
  lastName,
  email,
}: Props): JSX.Element => {
  const formattedName = [lastName, firstName].filter(Boolean).join(', ');
  const hasEmail = isDefined(email);

  return (
    <div className="user-dropdown-header">
      <ac-flex
        alignItems={AlignItems.center}
        class="ac-spacing-bottom-sm ac-gap-sm"
      >
        <ac-icon icon={IconName.profile} />
        <ac-flex direction={FlexDirection.column}>
          <ac-text weight={TextWeight.semibold}>{formattedName}</ac-text>
        </ac-flex>
      </ac-flex>
      {hasEmail && (
        <ac-flex class="ac-spacing-bottom-sm ac-spacing-left-lg">
          <ac-text class="ac-spacing-left-s" color={TextColor.lightGray}>
            {email}
          </ac-text>
        </ac-flex>
      )}
    </div>
  );
};
