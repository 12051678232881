/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { LoaderSize } from '@ac/web-components';

import { APPLICATION_ROUTES } from '@translations/features/application/config/routes.config';
import { useAuthorize } from '@translations/features/application/features/login/commands/authorize';

export const LoginContainer = (): JSX.Element => {
  const authorize = useAuthorize();
  const navigate = useNavigate();

  useEffect(() => {
    authorize({
      onSuccess: () => {
        const returnUrl =
          new URL(window.location.href).searchParams.get('returnUrl') ||
          APPLICATION_ROUTES.HOME;
        navigate(returnUrl, { replace: true });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ac-loader-covering loaderSize={LoaderSize.sm} />;
};
