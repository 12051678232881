import { useState } from 'react';

import { UserDetails } from '@ac/library-api/dist/types';

import {
  AuthenticatedUserType,
  AuthenticationState,
  getDefaultAuthenticationState,
} from '@translations/features/application/features/login/store/state';
import { LoadingStatus } from '@translations/types/shared';

export interface AuthenticationStore {
  state: AuthenticationState;
  setAuthenticatedUserType: (
    authenticatedUserType: AuthenticatedUserType
  ) => void;
  setCurrentUser: (currentUser: UserDetails) => void;
  setLoadingStatus: (loadingStatus: LoadingStatus) => void;
}

export const useAuthenticationStore = (): AuthenticationStore => {
  const [state, setState] = useState<AuthenticationState>(
    getDefaultAuthenticationState()
  );

  const setAuthenticatedUserType = (
    authenticatedUserType: AuthenticatedUserType
  ): void => setPartialState({ authenticatedUserType });

  const setCurrentUser = (currentUser: UserDetails): void =>
    setPartialState({ currentUser });

  const setLoadingStatus = (loadingStatus: LoadingStatus): void =>
    setPartialState({ loadingStatus });

  const setPartialState = (newState: Partial<AuthenticationState>): void =>
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));

  return {
    state,
    setAuthenticatedUserType,
    setCurrentUser,
    setLoadingStatus,
  };
};
